var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import get from "lodash.get";
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isChecked = function () {
            return get(_this.props.form.values, _this.props.field.name);
        };
        _this.triggerCheckboxChange = function (ev) {
            _this.props.form.setFieldValue(_this.props.field.name, ev.currentTarget.checked);
        };
        _this.getHiddenName = function () {
            var name = get(_this.props.form.values, 'user.name');
            if (typeof name === "undefined" || !name.length)
                return name;
            name = name.trim().split(' ');
            if (name.length === 1)
                return name[0];
            var firstName = name[0];
            var lastInitial = _this.returnFirstLetter(name[name.length - 1]);
            return "".concat(firstName, " ").concat(lastInitial);
        };
        _this.returnFirstLetter = function (value) {
            var _a;
            return (_a = value.split('')[0]) !== null && _a !== void 0 ? _a : '';
        };
        _this.render = function () {
            var name = _this.props.field.name.replace('.', '-');
            var isChecked = _this.isChecked();
            var hiddenName = _this.getHiddenName();
            return (React.createElement("div", { className: "singular-checkbox" },
                React.createElement("div", { className: "checkbox" },
                    React.createElement("input", { id: "checkbox-".concat(name), className: "checkbox__control", value: "yes", type: "checkbox", checked: isChecked, onChange: _this.triggerCheckboxChange }),
                    React.createElement("label", { htmlFor: "checkbox-".concat(name), className: "checkbox__label" },
                        React.createElement("span", { className: "label" },
                            React.createElement("span", { className: "label__main", dangerouslySetInnerHTML: { __html: _this.props.label } }),
                            _this.props.isNameCheckbox && isChecked && (React.createElement("small", { className: "label__secondary" },
                                "Your name will be displayed as ",
                                React.createElement("strong", null, hiddenName))))))));
        };
        return _this;
    }
    return Checkbox;
}(React.PureComponent));
export default Checkbox;
